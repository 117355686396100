import React from 'react';
import { Link } from 'react-router-dom';
import './Button.scss';

const Button = ({ onClick, children, active, href }) => {
  return (
    <div className='ButtonContainer'>
      <a href={href} target="_blank" rel="noreferrer">
        <button className={`Button  ${active ? "active" : ""}`} onClick={onClick}>
          {children}
        </button>
      </a>
    </div>
  )
}

export default Button;

import React from "react";
import "./Loading.scss";
import PropTypes from "prop-types";
import { useWindowSizeHook } from "../../../components/hooks/WindowHooks";

const Loading = ({ visible, message }) => {
  const { height, width } = useWindowSizeHook();

  return (
    <section className={`Loading ${visible ? "visible" : null} `}>
      <div style={{ height: height, width: width }}>
        <div className={"container"}>
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </div>
        {message && <p>{message}</p>}
      </div>
    </section>
  );
};

Loading.propTypes = {
  visible: PropTypes.bool.isRequired
};

export default Loading;

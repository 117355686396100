import React, { useEffect, useRef, useState } from 'react';
import { exportComponentAsPNG } from 'react-component-export-image';
import { useNavigate } from "react-router-dom";
import achutti from '../../../assets/images/achutti.png';
import ibm from '../../../assets/images/certificate/ibm.png';
import mastertech from '../../../assets/images/certificate/mastertech.png';
import match from '../../../assets/images/certificate/match.png';
import './Certificate.scss';

const Certificate = ({ name, studentTurma, canSeeCertificate, hasBadges, badges, courses, moduleName, workload, filePrefix }) => {
  const [isExporting, setIsExporting] = useState(false);

  let navigate = useNavigate();

  useEffect(() => {
    if (!canSeeCertificate()) {
      navigate("/login")
    }
  }, [name, canSeeCertificate, studentTurma, navigate]);

  const certificateRef = useRef(null);

  let tableData = courses;
  if (hasBadges()) {
    tableData = [
      ...tableData,
      ...badges,
    ];
  }

  const handleExportPDF = () => {
    const pdfWidth = 1920;
    const pdfHeight = 1080;
    setIsExporting(true);

    setTimeout(() => {
      exportComponentAsPNG(certificateRef, { x: 0, y: 0, w: pdfWidth, h: pdfHeight, fileName: filePrefix +name.split(" ")[0].toLowerCase() })
        .then(() => {
          setIsExporting(false);
        });
    }, 100);
  };

  let month = new Date().toLocaleString('pt-BR', { month: 'long' });
  month = month.charAt(0).toUpperCase() + month.slice(1);

  return (
    <div className='container'>
      <div className='download-pdf'>
        <h1>🥳Parabéns, {name}!🥳</h1>
        <button onClick={handleExportPDF}>BAIXAR CERTIFICADO</button>
      </div>
      {isExporting && (
        <div ref={certificateRef} className="Certificate" id='Certificate'>
          <div className='container-certificate'>
            <p className='min-title'>Certificamos que</p>
            <p className='title'>{name}</p>
          </div>
          <div className='infos-certificate'>
            <div className='assinature'>
              <p>Participou e concluiu com êxito o <strong>{moduleName},</strong> ministrado pela Mastertech,<br /> na modalidade 100% remoto, em parceria com a IBM.<br /> Carga horária estimada: {workload} horas.</p>
              <p className='date'>São Paulo, {month} de 2024</p>
              <img src={achutti} alt='Assinatura de Camila Achutti' />
              <p className='info-assinature'>CAMILA ACHUTTI, CEO DA <strong>MASTERTECH</strong></p>
            </div>

            <div className='table-certificate'>
              <ol className="collection collection-container">
                <li className="item item-container">
                  <div className="attribute-container">
                    <div className="attribute"><p>TEMA CURRICULAR </p></div>
                  </div>
                  <div className="attribute-container">
                    <div className="attribute"><p>CONTEÚDO PRINCIPAL</p></div>
                  </div>
                </li>
                {tableData.map((item, index) => {
                  return (
                    <li key={index} className="item item-container">
                      <div className="attribute-container part-information">
                        <div className="attribute-container part-id">
                          <div className="attribute" data-name="CONTEÚDO">
                            <p
                              dangerouslySetInnerHTML={{
                                __html: item.tema || "Conteúdo sem descrição",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="attribute-container quantity">
                        <div className="attribute" data-name="ONDE?">
                          <p
                            dangerouslySetInnerHTML={{
                              __html: item.content || "Conteúdo sem descrição",
                            }}
                          />
                        </div>
                      </div>
                    </li>
                  )
                })}
              </ol>
            </div>
          </div>
          <div className='footer'>
            <img className='match' src={match} alt='Logo do Match' />
            <div>
              <img className='logos m' src={mastertech} alt='Logo do Mastertech' />
              <img className='logos' src={ibm} alt='Logo da IBM' />
            </div>
          </div>
        </div>
      )}
    </div>

  );
}

export default Certificate;

import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import logoMatch from '../../../assets/images/match-logo.png';
import NavigationLink from '../../common/NavigationLink/NavigationLink';
import './Navbar.scss';

const Navbar = () => {
  const { pathname } = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);
  const [classNav, setClassNav] = useState("");
  const { hash } = useLocation();

  const handleMenu = () => {
    if (menuOpen) {
      setMenuOpen(false);
      setClassNav("menu-closed");
    } else {
      setMenuOpen(true);
      setClassNav("menu-active");
    }
  };

  useEffect(() => {
    setClassNav('menu-closed')
  }, [hash, pathname])

  return (
    <nav className={`Navbar ${classNav}`}>
      <a href={window.location.origin}>
        <img alt='Logo da Impulso' src={logoMatch} />
      </a>
      <button>
        <div aria-label={menuOpen ? "fechar menu" : "abrir menu"} onClick={handleMenu}>
          <span />
          <span />
          <span />
        </div>
      </button>
      <div>
        <NavigationLink href='/#jornada' active={hash === '#jornada'}>A jornada</NavigationLink>
        <NavigationLink href='/#metodologia' active={hash === '#metodologia'}>A metodologia</NavigationLink>
        <NavigationLink href='/#estatisticas' active={hash === '#estatisticas'}>As estatísticas</NavigationLink>
        <NavigationLink href='/#idealizadores' active={hash.includes('#idealizadores')}>Os idealizadores</NavigationLink>
        <a target="_blank" href="https://skills.yourlearning.ibm.com/activity/PLAN-77C3CABFEC6A?utm_campaign=open-Mastertech" class="btn-info" role="button" rel="noreferrer">INSCREVA-SE GRATUITAMENTE</a>
      </div>
    </nav>
  )
}

export default Navbar;

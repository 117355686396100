import React, { useEffect, useState } from 'react'
import './ContentAvaliation.scss';
import arrowImage from '../../../../assets/images/processStudent/arrow-right.svg'
import CardsHelp from '../CardsHelp/CardsHelp';
import { useUser } from '../../../hooks/UserContext';
import { useNavigate } from 'react-router-dom';

const ContentAvaliation = ({ data }) => {
  const { updateUserProjectInfo } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (!data) {
      navigate("/login")
    }
  }, []);


  const handleRedirect = () => {
    const url = `https://hub.mastertech.com.br/registrar?mail=${data.email}&name=${data.name}`;
    window.open(url, '_blank');
  }

  useEffect(() => {
    if (data && Object.keys(data?.project_info).length === 0) {
      if (data?.cf_skillsbuild_m1_project && data?.cf_skillsbuild_m2_project) {
        const updateInfo = async () => {
          await updateUserProjectInfo({
            status: "PROJECTALL"
          });
        };
        updateInfo();
      } else if (data?.cf_skillsbuild_m1_project) {
        const updateInfo = async () => {
          await updateUserProjectInfo({
            status: "PROJECT1"
          });
        };
        updateInfo();
      } else if (data?.cf_skillsbuild_m2_project) {
        const updateInfo = async () => {
          await updateUserProjectInfo({
            status: "ALLPROJECT"
          });
        };
        updateInfo();
      }
    }
  }, [data, data?.cf_skillsbuild_m2_project, data?.project_info, updateUserProjectInfo]);
  
  const renderButtonCertificate = () => {
    if (data?.cf_skillsbuild_m1_project && data?.cf_skillsbuild_m2_project) {
      return (
        <div className='two-button'>
          <button onClick={() => navigate(`/certificado/aluno/${data?.project_info?.email}`)}>Baixar certificado 1<img src={arrowImage} alt='flecha' /></button>
          <button onClick={() => navigate(`/certificado2/aluno/${data?.project_info?.email}`)}>Baixar certificado 2<img src={arrowImage} alt='flecha' /></button>
        </div>
      )
    } else if (data?.cf_skillsbuild_m1_project && data?.cf_skillsbuild_m1_project.replace(/[^\w\sÃÇ]/gi, '') === "APROVADO") {
      return <button onClick={() => navigate(`/certificado/aluno/${data?.project_info?.email}`)}>Baixar certificado 1<img src={arrowImage} alt='flecha' /></button>
    } else if (data?.cf_skillsbuild_m2_project && data?.cf_skillsbuild_m2_project.replace(/[^\w\sÃÇ]/gi, '') === "APROVADO") {
      return <button onClick={() => navigate(`/certificado2/aluno/${data?.project_info?.email}`)}>Baixar certificado 1<img src={arrowImage} alt='flecha' /></button>
    } else {
      return <button onClick={() => navigate(`/certificado-final/aluno/${data?.project_info?.email}`)}>Baixar certificado<img src={arrowImage} alt='flecha' /></button>
    }
  }

  const renderStatus = () => {
    if (data?.project_info?.status === "PROJETO NÃO ENTREGUE") {
      return <>
        <div className='header-content'>
          <div className='card-status'>
            <p>STATUS DO PROJETO FINAL</p>
            <div>
              <strong>NÃO ENTREGUE </strong>
              <span>❌</span>
            </div>
          </div>
          <div className='description-header'>
            <strong style={{ color: "#f03a17" }}>CARREGANDO...</strong>
            <p>Você ainda não nos enviou o seu projeto.
              Para fazê-lo, basta clicar no link abaixo, mas caso exista alguma dúvida pendente em relação a ele, desenvolvimento ou entrega, confira o tutorial disponível logo ao final deste texto, no tópico "ACESSOS RÁPIDOS DO MATCH!".</p>
            <button onClick={() => window.open("https://mastertech-tech.typeform.com/to/oE2bIuqg", "_blank")} >
              Acessa link de entrega <img src={arrowImage} alt='flecha' /></button>
          </div>
        </div>
        <div className='line-separator'>
          <p>ACESSOS RÁPIDOS DO <strong>MATCH!</strong></p>
        </div>
        <div className='body-content'>
          <CardsHelp />
        </div>
      </>
    } else if (data?.project_info?.status === "APROVADO" || data?.project_info?.status === "PROJECT1" || data?.project_info?.status === "PROJECT3" || data?.project_info?.status === "PROJECTALL") {
      return <>
        <div className='header-content'>
          <div className='card-status'>
            <p>STATUS DO PROJETO FINAL</p>
            <div>
              <strong>PROJETO APROVADO!</strong>
              <span>✅</span>
            </div>
          </div>
          <div className='description-header'>
            <strong style={{ color: "#00d26a" }}>PARABÉNS!</strong>
            <p>Seu projeto foi aprovado sem ressalvas!
              Agora você já pode baixar o seu certificado de conclusão do Match! e correr para compartilhá-lo nas mídias sociais e plataformas de empregabilidade! Boa sorte e até a próxima!</p>
            {renderButtonCertificate()}
            {/* <button onClick={() => navigate(`/certificado2/aluno/${data?.project_info?.email}`)}>Baixar certificado <img src={arrowImage} /></button> */}
          </div>
        </div>
        <div className='line-separator'>
          <p>CONHEÇA AGORA O <strong>HUB DE EMPREGABILIDADE</strong></p>
        </div>
        <div className='body-content'>
          <div className="box-content approved">
            <span>Fala, formando(a)!</span>
            <p>É com muito orgulho que te damos as boas vindas ao <strong>Hub de Empregabilidade da Mastertech</strong> 🚀</p>
            <p>Estamos empolgados por ter você aqui nessa nova etapa da sua carreira.Cadastre-se agora e comece essa jornada <br /> em busca da sua primeira oportunidade em tecnologia. Boa sorte!</p>
            <button onClick={() => handleRedirect()}>Acessar o hub agora</button>
          </div>
        </div>
      </>
    } else if (data?.project_info?.status === "AGUARDANDO CORREÇÃO") {
      return <>
        <div className='header-content'>
          <div className='card-status'>
            <p>STATUS DO PROJETO FINAL</p>
            <div>
              <strong>AGUARDANDO CORREÇÃO</strong>
              <span>✍️</span>
            </div>
          </div>
          <div className='description-header'>
            <strong style={{ color: "#ffc83d" }}>PROJETO ENTREGUE!</strong>
            <p>Seu projeto foi recebido com sucesso pela nossa equipe! Agora ele será apreciado e dentro de um período de 10 dias, entraremos em contato para te dar uma devolutiva sobre o trabalho entregue. Até breve!</p>
          </div>
        </div>
        <div className='line-separator'>
          <p>ACESSOS RÁPIDOS DO <strong>MATCH!</strong></p>
        </div>
        <div className='body-content'>
          <CardsHelp />
        </div>
      </>
    } else if (data?.project_info?.status === "PRECISA DE MELHORIAS") {
      return <>
        <div className='header-content'>
          <div className='card-status'>
            <p>STATUS DO PROJETO FINAL</p>
            <div>
              <strong>PRECISA DE MELHORIAS</strong>
              <span>🔧</span>
            </div>
          </div>
          <div className='description-header'>
            <strong style={{ color: "rgb(147 145 145)" }}>UM MINUTO DA SUA ATENÇÃO!</strong>
            <p>Após a correção, notamos que seu projeto precisa de pequenos ajustes. Para empreendê-los, confira a devolutiva que o nosso time preparou para você. Depois, é só percorrer a mesma trilha e aguardar pelo resultado!</p>
            <button onClick={() => window.open("https://mastertech-tech.typeform.com/to/oE2bIuqg", "_blank")} >Entregar novamente <img src={arrowImage} alt='flecha' /></button>
          </div>
        </div>
        <div className='line-separator'>
          <p>FEEDBACK DO TIME <strong>MASTERTECH</strong></p>
        </div>
        <div className='body-content'>
          <div className="box-content">
            <span>OLÁ, {data?.project_info?.name}</span>
            <p>{data?.project_info?.history[0].reason}</p>
          </div>
        </div>
      </>
    }
  }
  return (
    <div className='ContentAvaliation'>
      {renderStatus()}
    </div>
  )
}

export default ContentAvaliation

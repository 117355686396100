import React from 'react';
import Certificate from "../Certificate";
import {useUser} from "../../../hooks/UserContext";

const CertificateModule2 = () => {
  const { email: userData } = useUser();
  const studentTurma = userData && userData?.cf_turma ? userData?.cf_turma.match(/^[^(]+/)[0].toLowerCase().trim() : ""

  const CanSeeCertificate = () => {
    let projectStatus = (userData["cf_skillsbuild_m2_project"] || "Incomplete")
      .replace("{APROVADO}", "Completed")
      .replace("APROVADO", "Completed")
      .replace("NÃO ENTREGUE", "NÃO ENTREGUE")
      .replace("{REPROVADO}", "REPROVADO")
      .replace("AGUARDANDO CORREÇÃO", "In Progress")
    return !studentTurma || projectStatus === "Completed"
  }

  const HasBadges = () => {
    return true // we dont have a way to check if the user has badges
  }

  const badges = [
    {
      tema: "Badge da IBM: Explorando tecnologias emergentes",
      content: "Computação em nuvem, inteligência artificial, segurança cibernética, ciência de dados (dados e análise), blockchain e internet das coisas."
    },
    {
      tema: "Badge da IBM: Requisitos essenciais para candidatura de emprego",
      content: "Desenvolvimento de um plano de carreira que se adapte às habilidades e ambições, ao currículo e às habilidades de entrevista que podem te ajudar a se destacar na busca por um emprego."
    },
  ]

  const courses = [
    {
      tema: "Noções básicas da Internet",
      content: "Princípios básicos do funcionamento da internet e da WWW."
    },
    {
      tema: "Noções básicas de Programação",
      content: "Os princípios das linguagens de programação mais usadas atualmente, suas diferenças, e as vantagens e especificidades de cada tipo."
    },
    {
      tema: "Noções básicas de GIT e GITHUB",
      content: "A filosofia e o modo de trabalho do repositório de controle de versões de software mais usado na atualidade, GitHub."
    },
    {
      tema: "Fundamentos de Desenvolvimento Web",
      content: "Os fundamentos da programação de páginas Web. Diferenças de programação entre front-end, back-end e fullstack."
    },
    {
      tema: "Noções básicas de Python",
      content: "Introdução a programação python e suas principais funcionalidades."
    },
    {
      tema: "Programação Orientada a Objetos com Python",
      content: "Como a programação orientada a objetos permite simplificação dos grandes programas que envolvem objetos que interagem entre si."
    },
  ]

  return (
    <Certificate
      name={userData?.name}
      studentTurma={studentTurma}
      canSeeCertificate={CanSeeCertificate}
      hasBadges={HasBadges}
      badges={badges}
      courses={courses}
      moduleName={<>Módulo 2 - Desenvolvimento <br /> Web</>}
      workload={42}
      filePrefix={"certificado-modulo-2-"}
    />
  );
};

export default CertificateModule2;

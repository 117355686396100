import React from 'react'
import './CardsHelp.scss'

const CardsHelp = () => {
  return (
    <div className='CardsHelp'>

      {/* Card */}
      <div className="card">
        <div className="card-header">
          <span>ACESSO RÁPIDO</span>
          <div>
            <p>ESCOLHA O SEU <br /> PROJETO FINAL </p>
            <strong>📒</strong>
          </div>
        </div>
        <div className="body-card">
          <p>Conheça nesse documento os projetos finais do Match!</p>
          <button onClick={() => window.open("https://docs.google.com/document/d/e/2PACX-1vQ_vJY3N9QtfFZaq24_kXPwwjvnYUHKw0PVA_9mf-QKoGstpcnWScumNmKhGBc0q5O0cdn6eslpDLy8/pubm", "_blank")}>Acessar documentos <span>➜</span></button>
        </div>
      </div>

      {/* Card */}
      <div className="card">
        <div className="card-header">
          <span>ACESSO RÁPIDO</span>
          <div>
            <p>COMO ENTREGAR <br /> MEU PROJETO? </p>
            <strong>📋</strong>
          </div>
        </div>
        <div className="body-card">
          <p>Está com dúvidas de como entregar o seu projeto? Vem que a Camila Achutti te explica ;)</p>
          <button>Acessar tutorial <span>➜</span></button>
        </div>
      </div>

      {/* Card */}
      <div className="card">
        <div className="card-header">
          <span>ACESSO RÁPIDO</span>
          <div>
            <p>SKLILLSBUILD </p>
            <strong>🖥️</strong>
          </div>
        </div>
        <div className="body-card">
          <p>Acesse o seu painel na SKILLSBUILD.</p>
          <button onClick={() => window.open("https://skills.yourlearning.ibm.com/activity/PLAN-77C3CABFEC6A?utm_campaign=open-Mastertech", "_blank")}>Acessar tutorial <span>➜</span></button>
        </div>
      </div>

      {/* Card */}
      <div className="card">
        <div className="card-header">
          <span>ACESSO RÁPIDO</span>
          <div>
            <p>COMUNIDADE <br /> NO DISCORD </p>
            <strong>📱</strong>
          </div>
        </div>
        <div className="body-card">
          <p>Ainda não está na nossa comunidade? Entre agora mesmo.</p>
          <button onClick={() => window.open("https://discord.gg/qsJwdPCw7J", "_blank")}>Acessar Discord <span>➜</span></button>
        </div>
      </div>
    </div>
  )
}

export default CardsHelp

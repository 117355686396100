import React from 'react';
import computer from '../../../assets/images/computer.png';
import './DevelopmentWeb.scss';

const DevelopmentWeb = () => {
  return (
    <div className='DevelopmentWeb'>
      <h6>MÓDULO BÔNUS 2: <strong>DESENVOLVIMENTO WEB</strong></h6>
      <div className='container-web'>
        <div className='left-web'>
          <div className="texts-left">
            <p>
              Estudos da Associação Brasileira das Empresas de Tecnologia da Informação e Comunicação (Brasscom) estimam que o déficit de especialistas em TI deve ultrapassar meio milhão de profissionais até 2025. E nessa conta, o Brasil tem um desafio: por ano, apenas 53 mil pessoas se qualificam para ocupar esses postos.
            </p>
            <p>
              Mas onde há defasagem, há oportunidade, e é por isso que o Match! criou um módulo bônus sobre Desenvolvimento Web!
            </p>
            <p>
              Ancorado na Cultura Ágil e no Design Thinking, ele se dedica às linguagens e ferramentas de programação e desenvolvimento web e apresenta conteúdos como:
            </p>
          </div>
          <div className="container-infos-texts" >
            <div className="infos-div">
              <div className='ball'></div>
              <p>Noções básicas da Internet;</p>
            </div>
            <div className="infos-div">
              <div className='ball'></div>
              <p>Noções básicas de programação;</p>
            </div>
            <div className="infos-div">
              <div className='ball'></div>
              <p>Noções básicas sobre GIT e GitHub;</p>
            </div>
            <div className="infos-div">
              <div className='ball'></div>
              <p>Fundamentos de Desenvolvimento Web;</p>
            </div>
          </div>
          <div className="end-left">
            <p>
              Mas diferente dos módulos de IA e de Atendimento ao Cliente Digital, este é facultativo, o que significa que ele não está vinculado à jornada educativa que garantirá o seu certificado no Match!.
            </p>
          </div>
        </div>

        <div className="right-web">
          <div className="container-image">
            <img src={computer} alt="Computador" />
          </div>
          <div className="container-infos-texts" >
            <div className="infos-div">
              <div className='ball'></div>
              <p>Noções básicas de Python;</p>
            </div>
            <div className="infos-div">
              <div className='ball'></div>
              <p>Programação em Python (Algoritmos);</p>
            </div>
            <div className="infos-div">
              <div className='ball'></div>
              <p>Teste com Python;</p>
            </div>
            <div className="infos-div">
              <div className='ball'></div>
              <p>Programação Orientada a Objetos em Python;</p>
            </div>
          </div>
          <div className="texts-right">
            <span>Contudo, entretanto, todavia...</span>
            <p>centrado na inclusão digital e na diversidade, com foco na capacitação profissional para o mercado de trabalho, ele é uma bela chance de mostrar para as empresas que você tem muito para oferecer!</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DevelopmentWeb

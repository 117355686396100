import React from 'react';
import StatisticsImage from '../../../assets/images/statistics/statistics.png';
import './Statistics.scss';

const Statistics = () => {
  return (
    <div className='Statistics' id='estatisticas'>
      <div className='title'>
        <h4><span>3</span>Estatísticas</h4>
      </div>
      <div className='subtitle-statistics'>
        <p>Um ambiente acolhedor, inspirador e repleto de possibilidades!</p>
      </div>
      <div className='container-statistics'>
        <div className='infos-statistics'>
          <p>O Match! é um programa adequado tanto para pessoas iniciantes quanto para aquelas em busca de transições profissionais. Isso é evidente na média de idade, que abrange diversas faixas, <span>desde jovens até adultos maduros.</span></p>

          <p><strong>Nossa presença abrange todo o território nacional,</strong> criando uma convivência repleta de diversidade, enriquecida ainda mais pela ênfase em atividades práticas e colaborativas, onde essas características se manifestam mais fortemente.</p>

          <p>A modalidade remota favorece o desenvolvimento dessa união. Com milhares de matrículas, alcançamos um quadro significativo em termos de diversidade <strong>(50% delas são de grupos raciais e 38% de mulheres)</strong>, sobretudo quando consideramos a defasagem destas minorias no âmbito das tecnologias.</p>
        </div>
        <img src={StatisticsImage} alt='Tabela em pizza' />
      </div>
    </div>
  )
}

export default Statistics

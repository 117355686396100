import React, { useEffect } from 'react'
import './Navbar.scss'
import { useUser } from '../../../hooks/UserContext';

const Navbar = () => {
  const { email, updateUserProjectInfo } = useUser();

  useEffect(() => {
    if (email && Object.keys(email.project_info).length === 0) {

      const updateInfo = async () => {
        await updateUserProjectInfo({
          email: email.email,
          name: email.name
        });
      };
  
      updateInfo();
    }
  }, [email, updateUserProjectInfo]);
  
  function getInitials(fullName) {
    if (fullName) {
      const nameParts = fullName?.split(' ');
      const initials = nameParts.slice(0, 2).map(part => part[0].toUpperCase()).join('');

      return initials;
    }
    
    return ''
  }

  console.log()
  return (
    <nav className='NavbarProcess'>
      <div className='container-navbar'>
        <h1>Match<span>!</span></h1>
        <div className='info-user-navbar'>
          <p className="image-info">{getInitials(email.project_info?.name)}</p>
          <div>
            <strong>{email.project_info?.name}</strong>
            <p>{email.project_info?.email}</p>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navbar

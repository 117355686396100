import React from 'react';
import Button from '../../common/Button/Button';
import './Creators.scss';

const Creators = () => {
  return (
    <div className='Creators' id='idealizadores'>
      <div className='title'>
        <h4><span>4</span>Idealizadores</h4>
      </div>
      <div className='container-creators'>
        <div>
          <div role="img" aria-label="Logo ibm" className='ibm-logo' />
          <p>IBM SkillsBuild é um programa de educação gratuito focado em tecnologia para comunidades vulneráveis com representação reduzida que ajuda tanto pessoas adultas, como estudantes do ensino médio, universitários e professores a desenvolver habilidades valiosas e ter acesso a oportunidades de carreira. O programa inclui uma plataforma on-line que é complementada por práticas customizadas de experiências de aprendizagem em colaboração com uma rede global de parceiros. O nosso público-alvo consiste em: mulheres, comunidades em desvantagem econômica, pessoas neurodiversas e refugiadas. <br /><br />Para obter mais informações, visite <br /> <span>www.skillsbuild.org</span>
          </p>
        </div>
        <div>
          <p><strong>MASTERTECH</strong></p>
          <p>A Mastertech, criada em 2015, é uma escola que desenvolve as competências necessárias para os desafios dos novos tempos. Tempos de rápidas transformações que demandam plasticidade cognitiva e adaptabilidade, tanto na esfera profissional quanto na pessoal. Assim, muito mais do que ensinar sobre cultura ágil, filosofia lean ou programação, nos propomos a cultivar, primordialmente, repertório, senso crítico e inteligência socioemocional, através da educação circular e da emancipação tecnológica, para o exercício pleno da cidadania em um mundo digital. <br /><br />Para obter mais informações, visite <span>www.mastertech.com.br</span>.</p>
        </div>
      </div>

      <div className='info'>
        <div className='info-header'>
          <p>Formação on-line de IA gratuita</p>
          <h1>Match<span>!</span></h1>
          <p>Criando conexões para<br /> o mercado de tecnologia.</p>
          <Button href="https://skills.yourlearning.ibm.com/activity/PLAN-77C3CABFEC6A?utm_campaign=open-Mastertech">INSCREVA-SE GRATUITAMENTE</Button>
          <div className='info-all'>
            <p className='partner-info'>PARCEIRO EDUCACIONAL:</p>
            <span className='span-partner'>MASTERTECH</span>
          </div>
          <div className='info-all-ibm'>
            <p className='partner-info'>EM COLABORAÇÃO COM:</p>
            <span className='span-partner'>IBM SkillsBuild</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Creators

import { createContext, useContext, useState } from 'react';

const UserContext = createContext();

export function UserProvider({ children }) {
  const [email, setEmail] = useState('');

  const updateUserProjectInfo = async (newProjectInfo) => {
    setEmail(prevUserData => {
      const newUserData = { ...prevUserData };
      newUserData.project_info = newUserData.project_info || {};
      newUserData.project_info = { ...newUserData.project_info, ...newProjectInfo };
      return newUserData;
    });
  };


  return (
    <UserContext.Provider value={{ email, setEmail, updateUserProjectInfo }}>
      {children}
    </UserContext.Provider>
  );
}

export function useUser() {
  return useContext(UserContext);
}
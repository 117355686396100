import React from 'react'
import './Footer.scss';

const Footer = () => {
  return (
    <div className='Footer'>
      <div className='footer-infos'>
        <p className='partner-info'>PARCEIRO EDUCACIONAL:</p>
        <span className='span-partner'>MASTERTECH</span>
      </div>
      <div>
        <p><strong>Ainda está com dúvidas?</strong></p>
        <p>Mande um email para <a href="mailto:treinamento@mastertech.com.br">treinamento@mastertech.com.br</a></p>
      </div>
    </div>
  )
}

export default Footer

import React, { useEffect } from 'react'
import './ProcessStudent.scss';
import ContentAvaliation from '../ContentAvaliation/ContentAvaliation';
import { useUser } from '../../../hooks/UserContext';
import { useNavigate } from 'react-router-dom';

const ProcessStudent = () => {
  const { email } = useUser();

  return (
    <section className='ProcessStudent'>
      <div className="container-infos">
        <ContentAvaliation data={email} />
      </div>
    </section>
  )
}

export default ProcessStudent

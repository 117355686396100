import React from 'react';
import { useUser } from "../../../hooks/UserContext";
import Certificate from "../Certificate";

const NewCertificate = () => {
  const { email: userData } = useUser();
  const studentTurma = userData && userData?.cf_turma ? userData?.cf_turma.match(/^[^(]+/)[0].toLowerCase().trim() : ""
  const CanSeeCertificate = () => {
    let projectStatus = (userData["cf_skillsbuild_m2_project"] || "Incomplete")
      .replace("{APROVADO}", "Completed")
      .replace("APROVADO", "Completed")
      .replace("NÃO ENTREGUE", "NÃO ENTREGUE")
      .replace("{REPROVADO}", "REPROVADO")
      .replace("AGUARDANDO CORREÇÃO", "In Progress")
    return !studentTurma || projectStatus === "Completed"
  }

  const HasBadges = () => {
    return true // we dont have a way to check if the user has badges
  }

  const badges = [
    {
      tema: "Badge da IBM: Explorando tecnologias emergentes",
      content: "Computação em nuvem, inteligência artificial, segurança cibernética, ciência de dados (dados e análise), blockchain e internet das coisas."
    },
    {
      tema: "Badge da IBM: Requisitos essenciais para candidatura de emprego",
      content: "Desenvolvimento de um plano de carreira que se adapte às habilidades e ambições, ao currículo e às habilidades de entrevista que podem te ajudar a se destacar na busca por um emprego."
    },
  ]

  const courses = [
    {
      tema: " Inteligência Artificial (IA)",
      content: "Exploração dos conceitos fundamentais da IA, incluindo uma compreensão de machine learning e deep learning, assim como o debate sobre a capacidade dos computadores de pensar."
    },
    {
      tema: "Operações de Computadores e Dispositivos Digitais",
      content: "História dos computadores, funcionamento da internet, e processos envolvidos em requisições web, incluindo o percurso de uma URL e estratégias para encontrar conteúdo relevante online."
    },
    {
      tema: "Tecnologias Emergentes",
      content: "Introdução a seis tecnologias em ascensão, abordando seus princípios básicos, terminologia e aplicações práticas."
    },
    {
      tema: "Fundamentos da Inteligência Artificial (IA)",
      content: "Profundização na história e aplicações da IA, incluindo simulações práticas para construir e testar modelos de aprendizado de máquina."
    },
    {
      tema: "Criando Prompts para Modelos de Linguagem de IA",
      content: "Exploração de estratégias para criar prompts eficazes para modelos de linguagem de IA, através de atividades práticas como planejamento de itinerários de viagem e criação de playlists personalizadas."
    },
    {
      tema: "Suporte de Clientes Digitais",
      content: "Análise das carreiras e demandas no suporte tecnológico, com base em estudos de caso e entrevistas reais."
    },
    {
      tema: "Resolução de Problemas Técnicos",
      content: "Aprendizado de técnicas de resolução de problemas, incluindo troubleshooting, uso de fluxogramas, e aplicação de pensamento computacional para lidar com desafios técnicos."
    },
    {
      tema: "Agile Explorer",
      content: "Compreensão dos conceitos ágeis, incluindo valores, princípios e práticas, através da conclusão de três cursos."
    },
    {
      tema: "Essenciais para a Candidatura de Emprego",
      content: "Desenvolvimento de habilidades essenciais para a busca de emprego, incluindo a criação de um plano de carreira, currículo e preparação para entrevistas."
    },
    {
      tema: "Projeto Final",
      content: "Demonstração de conhecimentos e habilidades em projetos práticos, com a escolha entre sete projetos abrangendo diferentes aspectos da tecnologia."
    },
  ]
  return (
    <Certificate
      name={userData?.project_info?.name}
      studentTurma={studentTurma}
      canSeeCertificate={CanSeeCertificate}
      hasBadges={HasBadges}
      badges={badges}
      courses={courses}
      moduleName={<>Programa Match!</>}
      workload={100}
      filePrefix={"certificado-"}
    />
  );
};

export default NewCertificate;

import React, { useState } from 'react';
import OneImage from '../../../assets/images/learn/one.svg';
import TwoImage from '../../../assets/images/learn/two.svg';
import DevelopmentWeb from '../DevelopmentWeb/DevelopmentWeb';
import './Learn.scss';

const Learn = () => {
  const [openModuleOne, setOpenModuleOne] = useState(false);
  const [openModuleTwo, setOpenModuleTwo] = useState(false);
  const [openModuleThree, setOpenModuleThree] = useState(false);

  return (
    <div className='Learn' id='jornada'>
      <div className='title'>
        <h4><span>1</span>A sua jornada no Match!</h4>
      </div>
      <div className="container-info">
        <p><span>Você poderá obter um certificado de 20 horas em menos de um mês de estudos! O tempo de conclusão é flexível e depende exclusivamente da sua dedicação. Em média, nossos alunos concluem o curso em apenas 3 semanas.</span></p>
        <p>Integralmente on-line, o Match! conta com dois etapas: <strong>"Inteligência Artificial" e um projeto final exclusivo, que será avaliado.</strong> A conclusão bem-sucedida do curso garante um certificado de grande valor para o seu currículo!</p>
        <p>Abaixo, <span>confira os 2 passos da sua certificação no Match!</span></p>
      </div>
      <div className="container-visibles">
        {/* Módulo 1 */}
        <div className="title-module" onClick={() => setOpenModuleOne(!openModuleOne)}>
          <img src={OneImage} alt='Imagem de número 1' />
          <p><span>Módulo 1: </span>Inteligência artificial</p>
          <div className='circle-click'>
            <span>{openModuleOne ? "-" : "+"}</span>
          </div>
        </div>
        <div className={`container-module one ${openModuleOne ? 'open' : ''}`}>
          <div className="text-containers">
            <p className='text-left'>Para apresentar às tecnologias emergentes que estão reconfigurando tanto o mercado de trabalho quanto às interações profissionais e sociais, e te preparar para esse universo hiperconectado e fervilhante, reunimos computação em nuvem (cloud computing), IA, segurança cibernética, coleta e análise de dados, Internet das Coisas (IoT) e blockchain no primeiro módulo do Match!. <br /><br /> Nesta jornada educacional, amparada por especialistas da IBM e educadores da Mastertech, vamos te ensinar os fundamentos, conceitos básicos, terminologias e - melhor de tudo! - as possibilidade de aplicação dessas ferramentas para que assim você possa decidir em qual - ou quais! - delas quer mergulhar mais fundo e se destacar!</p>
            <br />
            <p className='title-desktop'><span>Prepare-se, que palavras como machine learning, deep learning <br /> e prompt nunca mais vão te intimidar!</span></p>
            <p className='title-mobile'><span>Prepare-se, que palavras como machine learning, deep learning e prompt nunca mais vão te intimidar!</span></p>
          </div>
          <div className="container-infos-texts" >
            <div className='title'>
              <p><span>Ao longo do módulo você vai aprender:</span></p>
            </div>
            <div className="infos-div">
              <div className='ball'></div>
              <p>O que é inteligência artificial?</p>
            </div>
            <div className="infos-div">
              <div className='ball'></div>
              <p>Operações de Computadores e Dispositivos Digitais;</p>
            </div>
            <div className="infos-div">
              <div className='ball'></div>
              <p>O que são tecnologias emergentes;</p>
            </div>
            <div className="infos-div">
              <div className='ball'></div>
              <p>Fundamentos da inteligência artificial;</p>
            </div>
            <div className="infos-div">
              <div className='ball'></div>
              <p>Dominando a arte de criar prompts;</p>
            </div>
          </div>
        </div>

        {/* Módulo 2 */}

        <div className="title-module" onClick={() => setOpenModuleThree(!openModuleThree)}>
          <img src={TwoImage} alt='Imagem de número 1' />
          <p><span>Projeto final: </span>Crie um projeto profissional para o seu portfólio!</p>
          <div className='circle-click'>
            <span>{openModuleThree ? "-" : "+"}</span>
          </div>
        </div>
        <div className={`container-module one ${openModuleThree ? 'open' : ''}`}>
          <div className="text-containers">
            <p className='text-left'>Chegou a hora de sair da teoria, partir para a prática e amarrar a jornada de conhecimento proporcionada pelo Match! com um trabalho de conclusão de curso!<br /><br /> Você terá acesso a 7 projetos distintos e deverá escolher um deles para desenvolver o conteúdo transmitido tanto no primeiro quanto no segundo módulo, demonstrando de forma efetiva a sua criatividade e as habilidades adquiridas ao longo dos seus estudos.</p>
            <br />
            <p className='title-desktop'><span>Aproveite esta etapa para inovar e estruturar um material que pode<br /> ser o seu passaporte para uma oportunidade de emprego!</span></p>
            <p className='title-mobile'><span>Aproveite esta etapa para inovar e estruturar um material que pode ser o seu passaporte para uma oportunidade de emprego!</span></p>
          </div>
        </div>

        <div className={`container-bonus`}>
          <h6>MÓDULO BÔNUS 1: <strong>ATENDIMENTO AO CLIENTE E HABILIDADES PROFISSIONAIS</strong></h6>
          <div className='container-section'>
            <div className="text-containers">
              <p className='text-left'>A IA, tema central do primeiro módulo do Match!, não se cria sozinha… Além do mais, cedo ou tarde, todo mundo vai precisar da intervenção de um ser humano. E foi com esse pensamento em mente que estruturamos o segundo módulo da nossa jornada educacional: Fundamentos da Tecnologia para Atendimento ao Cliente Digital. <br /><br />Nesta etapa, você vai aprender sobre a história e o funcionamento dos computadores e da internet, e explorar com a gente as várias carreiras que se relacionam com a área de suporte por meio de conteúdos que vão dos métodos ágeis de gestão de projetos aos princípios da arquitetura de sistemas, passando por técnicas de comunicação e de vendas.</p>
              <br />
              <p className='title-desktop'><span>É o pontapé tecnológico que faltava para você estrear com segurança<br /> e embasamento no mercado de trabalho!</span></p>
              <p className='title-mobile'><span>É o pontapé tecnológico que faltava para você estrear com segurança e embasamento no mercado de trabalho!</span></p>
            </div>
            <div className="container-infos-texts" >
              <div className='title'>
                <p><span>Ao longo do módulo você vai aprender:</span></p>
              </div>
              <div className="infos-div">
                <div className='ball'></div>
                <p>Suporte de Clientes Digitais;</p>
              </div>
              <div className="infos-div">
                <div className='ball'></div>
                <p>Resolução de Problemas Técnicos;</p>
              </div>
              <div className="infos-div">
                <div className='ball'></div>
                <p>Agile Explorer - desenvolvido com Agile na IBM;</p>
              </div>
              <div className="infos-div">
                <div className='ball'></div>
                <p>Essenciais para a Candidatura de Emprego;</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <DevelopmentWeb />
    </div>
  )
}

export default Learn

import React from 'react';
import logoIBM from '../../../assets/images/image-header.png';
import Button from '../../common/Button/Button';
import './Header.scss';

const Header = () => {
  return (<div className='Header'>
    <div className='container-header-top-desktop'>
      <div className='info-header'>
        <p>Formação on-line de IA gratuita</p>
        <h1>Match<span>!</span></h1>
        <p>Criando conexões para<br /> o mercado de tecnologia.</p>
        <Button href="https://skills.yourlearning.ibm.com/activity/PLAN-77C3CABFEC6A?utm_campaign=open-Mastertech">INSCREVA-SE GRATUITAMENTE</Button>
        <div className='info-all'>
          <p className='partner-info'>PARCEIRO EDUCACIONAL:</p>
          <span className='span-partner'>MASTERTECH</span>
        </div>
        <div className='info-all-ibm'>
          <p className='partner-info'>EM COLABORAÇÃO COM:</p>
          <span className='span-partner'>IBM SkillsBuild</span>
        </div>
      </div>
      <img alt='Logo da Impulso' src={logoIBM} />
    </div>
    <div className='container-header-top-mobile'>
      <div className='info-header'>
        <p>Formação on-line de IA gratuita</p>
        <h1>Match<span>!</span></h1>
        <p>IBM criando conexões para <br /> o mercado de tecnologia</p>
        <img alt='Logo da Impulso' src={logoIBM} />
        <div className="container-infos">
          <div className='header-infos'>
            <p className='partner-info'>PARCEIRO EDUCACIONAL:</p>
            <span className='span-partner'>MASTERTECH</span>
          </div>
          <div className='header-infos-ibm'>
            <p className='partner-info'>EM COLABORAÇÃO COM:</p>
            <span className='span-partner'>IBM SkillsBuild</span>
          </div>
        </div>
        <Button href="https://skills.yourlearning.ibm.com/activity/PLAN-77C3CABFEC6A?utm_campaign=open-Mastertech">INSCREVA-SE</Button>
        {/* <Button href="">INSCRIÇÕES EM BREVE</Button> */}
      </div>
    </div>
    <div className='container-infos-course'>
      <div className='line' />
      <div className='container-courses'>
        <div className='box-container'>
          <span />
          <div className='box-course'>
            <div>
              <p><strong>O quê?</strong></p>
              <p>Uma formação totalmente on-line, criada em colaboração entre a IBM SkillsBuild e a Mastertech, destinada a iniciantes em tecnologia.</p>
            </div>
          </div>
        </div>

        <div className='box-container'>
          <span />
          <div className='box-course'>
            <div>
              <p><strong>Para quem?</strong></p>
              <p>Destinado a pessoas físicas maiores de 18 anos. Também oferecemos a opção de realizar eventos de sensibilização para instituições de terceiro setor que desejem apresentar essa oportunidade ao seu público.</p>
            </div>
          </div>
        </div>

        <div className='box-container'>
          <span />
          <div className='box-course'>
            <div>
              <p><strong>Como?</strong></p>
              <p>Com trilhas de conteúdo remotas, os alunos podem determinar seu próprio ritmo de aprendizado. Além disso, o calendário inclui mentorias e webinars conduzidos por especialistas da IBM.</p>
            </div>
          </div>
        </div>

        <div className='box-container'>
          <span />
          <div className='box-course'>
            <div>
              <p><strong>Quanto?</strong></p>
              <p>100% gratuito, desde a inscrição até a obtenção do certificado. Nosso objetivo é proporcionar uma jornada tecnológica acessível a todas as pessoas, reforçando nosso compromisso com a inclusão.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>);
}

export default Header;

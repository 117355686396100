import React from 'react';
import Certificate from "../Certificate";
import {useUser} from "../../../hooks/UserContext";

const CertificateModule1 = () => {
  const { email: userData } = useUser();
  const studentTurma = userData && userData?.cf_turma ? userData?.cf_turma.match(/^[^(]+/)[0].toLowerCase().trim() : ""

  const CanSeeCertificate = () => {
    let projectStatus = (userData["cf_skillsbuild_m1_project"] || "Incomplete")
      .replace("{APROVADO}", "Completed")
      .replace("APROVADO", "Completed")
      .replace("NÃO ENTREGUE", "NÃO ENTREGUE")
      .replace("{REPROVADO}", "REPROVADO")
      .replace("AGUARDANDO CORREÇÃO", "In Progress")
    return !studentTurma || projectStatus === "Completed"
  }

  const HasBadges = () => {
    return (userData.cf_skillsbuild_badge_1 === "Issued" && userData.cf_skillsbuild_badge_2 === "Issued")
      || (userData.cf_skillsbuild_badge_1 === "Completed" && userData.cf_skillsbuild_badge_2 === "Completed")
  }

  const badges = [
    {
      tema: "Certificação IBM<br/> Badge - Habilidades Profissionais",
      content: "criação de apresentações, abordagens ágeis, colaboração eficaz, comunicação assertiva, resolução de desafios e implementação de soluções."
    },
    {
      tema: "Badge - Explorador Ágil",
      content: "introdução às metodologias ágeis, fundamentos de operações e programas ágeis."
    },
  ]

  const courses = [
    {
      tema: "Operações de Computadores<br/> e Dispositivos Digitais",
      content: "história do computador, o funcionamento da internet, requisições web, URLs e como identificar conteúdo confiável, hardware, software, Back e Front-End, e arquitetura de sistemas."
    },
    {
      tema: "Suporte de Clientes Digitais",
      content: "suporte tecnológico, carreiras, demandas e aquisição de habilidades por meio de entrevistas com profissionais reais. As carreiras incluem: Help Desk, CX, CS, UI e UX."
    },
    {
      tema: "Resolução de Problemas Técnicos",
      content: "resolução de problemas, troubleshooting, fluxogramas, lógica e pensamento computacional, equipes e prevenção de problemas, incluindo segurança de dados."
    },
    {
      tema: "Técnicas de Comunicação, Vendas e Gestão de Crise",
      content: "comunicação não violenta no trabalho, vieses, habilidades de escrita e a LGPD para lidar com informações pessoais e ambientais."
    },
    {
      tema: "Agilidade: Gestão Efetiva de Demandas",
      content: "agilidade em processos e sua aplicação, gestão ágil e visual, priorização e melhoria contínua."
    },
  ]

  return (
    <Certificate
      name={userData?.name}
      studentTurma={studentTurma}
      canSeeCertificate={CanSeeCertificate}
      hasBadges={HasBadges}
      badges={badges}
      courses={courses}
      moduleName={<>Módulo 1 - Fundamentos de <br /> Tecnologia e Atendimento ao Cliente</>}
      workload={48}
      filePrefix={"certificado-modulo-1-"}
    />
  );
};

export default CertificateModule1;

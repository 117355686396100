import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import background from '../../../assets/images/Login/bg-login.png';
import ibmImage from '../../../assets/images/ibm-logo.png';
import matchLogo from '../../../assets/images/match-logo.png';
import Loading from '../../common/Loading/Loading';
import { useUser } from '../../hooks/UserContext';
import './Login.scss';

const Login = () => {
  const { setEmail } = useUser();
  const [email, setEmailLocal] = useState('');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();

  const handleLogin = () => {
    if (email) {
      setLoading(true);
      fetch(`https://alexandria.mastertech.com.br/ttr/rdstation/contact/${email}`)
        .then((response) => response.json())
        .then((result) => {
          setLoading(false);
          if (!result.message) {
            localStorage.setItem('userEmail', email);
            setEmail(result);
            navigate("/estudante")
          } else {
            setError(true)
          }
        })
        .catch(() => {
          setLoading(false);
        })
    }
  };

  return (
    <div className='Login'>
      <Loading visible={loading} message="Buscando aluno..." />
      <img src={background} alt='background' className='background one' />
      <img src={background} alt='background' className='background two' />
      <div className="container-login">
        <div className='info'>
          <div className='info-header'>
            <p>Formação on-line de IA gratuita</p>
            <img src={matchLogo} alt="Logo do match" />
          </div>
        </div>
        <div className="enter-login">
          <div>
            <label htmlFor="email">DIGITE SEU EMAIL:</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmailLocal(e.target.value)}
            />
          </div>
          <button onClick={handleLogin}>ENTRAR</button>
          {error && <p className='error'>O Email não consta em nosso banco de dados!</p>}
        </div>
      </div>
      <div className="creators-info">
        <div>
          <p>PARCEIRO EDUCACIONAL:</p>
          <p><strong>MASTERTECH</strong></p>
        </div>
        <div>
          <p>EM COLABORAÇÃO COM:</p>
          <p><strong>IBM SkillsBuild</strong></p>
        </div>
        <img src={ibmImage} alt='logo IBM' />
      </div>
    </div>
  )
}

export default Login

import React from 'react';
import ArrowRightBlue from '../../../assets/images/learn/arrow-right-blue.svg';
import Student from '../../../assets/images/methodology/aluna.png';
import Group from '../../../assets/images/methodology/clube.png';
import Conection from '../../../assets/images/methodology/conexao.png';
import Geek from '../../../assets/images/methodology/geek.png';
import Learning from '../../../assets/images/methodology/learning.png';
import Button from '../../common/Button/Button';
import "./Methodology.scss";

const Methodology = () => {
  return (
    <div className='Methodology' id='metodologia'>
      <div className='title'>
        <h4><span>2</span>A metodologia Mastertech</h4>
      </div>

      <div className='subtitle-statistics'>
        <p>Com quase uma década de experiência em metodologia e didática na área de tecnologia, a Mastertech destaca-se como uma escola de pensamento digital que valoriza a colaboração como método educacional e enxerga os projetos como soluções para aprimorar a formação acadêmica.</p>
      </div>

      <p className='sub-title-methodology'>Nesse contexto, o <span>Match!</span> proporciona o desenvolvimento em:</p>

      <div className="container-methodology">
        <div className='cards'>
          <div className="card-title">
            <span>Ignição</span>
            <p>Desafios reais <br /> orientados por<br /> especialistas.</p>
          </div>
          <img className='arrow-blue' src={ArrowRightBlue} alt='Seta azul' />
          <div className='card-info'>
            <div className="top-card">
              <div className='info-top-card'>
                <div className='title-min-card'>
                  <img src={Learning} alt='Estudos' />
                  <p>Aprendizagem <br /> Baseada em Projetos</p>
                </div>
                <p>No Match!, adotamos a abordagem pedagógica da <strong>"Aprendizagem Baseada em Projetos"</strong>, que coloca estudantes no centro do processo educacional. Isso possibilita que a pessoa construa conhecimento, desenvolva habilidades e aprimore competências ao criar e executar projetos concretos e direcionados.</p>
              </div>
            </div>
          </div>
        </div>

        <div className='cards'>
          <div className="card-title">
            <span>Aceleração</span>
            <p>Trabalho e interação em comunidade</p>
          </div>
          <img className='arrow-blue' src={ArrowRightBlue} alt='Seta azul' />
          <div className='card-info'>
            <div className="top-card">
              <div className='info-top-card'>
                <div className='title-min-card'>
                  <img src={Conection} alt='Conexão' />
                  <p>Curso on-line <br /> em comunidade</p>
                </div>
                <p><strong>Por meio do Discord e Youtube,</strong> promovemos mentorias ao vivo e webinars com especialistas da IBM, criando uma experiência coletiva e colaborativa que é essencial para fortalecer o engajamento na formação.</p>
              </div>
            </div>

          </div>
        </div>

      </div>
    </div>
  )
}

export default Methodology

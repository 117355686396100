import { BrowserRouter, Route, Routes } from "react-router-dom";
import { UserProvider } from "./hooks/UserContext";
import Creators from "./pages/Creators/Creators";
import Header from "./pages/Header/Header";
import Learn from "./pages/Learn/Learn";
import Login from "./pages/Login/Login";
import Marketplace from "./pages/Marketplace/Marketplace";
import Methodology from "./pages/Methodology/Methodology";
import Statistics from "./pages/Statistics/Statistics";
import Footer from "./ui/Footer/Footer";
import Navbar from "./ui/Navbar/Navbar";
import CertificateModule1 from "./ui/Certificate/Module1/CertificateModule1";
import CertificateModule2 from "./ui/Certificate/Module2/CertificateModule2";
import ProcessStudent from "./pages/processStudent/ProcessStudent/ProcessStudent";
import NavbarProcess from "./pages/processStudent/Navbar/Navbar";
import NewCertificate from "./ui/Certificate/NewModule/NewCertificate";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<>
            <Navbar />
            <Header />
            <Marketplace />
            <Learn />
            <Methodology />
            <Statistics />
            <Creators />
            <Footer />
          </>} />
          <Route path="/estudante" element={<UserProvider><NavbarProcess/><ProcessStudent /></UserProvider>} />
          <Route path="/login" element={<UserProvider><Login /></UserProvider>} />
          <Route path="/certificado-final/aluno/:email" element={<UserProvider><NewCertificate/></UserProvider>} />
          <Route path="/certificado/aluno/:email" element={<UserProvider><CertificateModule1 /></UserProvider>} />
          <Route path="/certificado2/aluno/:email" element={<UserProvider><CertificateModule2 /></UserProvider>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;